<template>
  <div class=container>
<table class=table width="73%" border="0" cellspacing="30" cellpadding="0">
		
				<tbody><tr>
					<td><div align="center"><font size="+2">FaceTracer Database</font></div></td>
				</tr><tr>
						<td><center><img src="https://cave.cs.columbia.edu/old/databases/facetracer/images/teaser.png" width="700"></center></td></tr><tr><td><p>The FaceTracer database is a large collection of real-world face images, collected from the internet. Each of the <b>15,000</b> faces in the database has a variety of metadata and fiducial points marked. In addition, a large subset of the faces contain hand-labeled descriptive attributes, including demographic information such as age and race, facial features like mustaches and hair color, and other attributes such as expression, environment, etc. There are <b>5,000</b> labels in all.</p><p>
</p><p>This rich dataset can be used for a variety of different applications. Since it is composed of real-world images collected in the wild, it provides a much more representative sample of typical images than other, more controlled, datasets -- there is large variation in pose, environment, lighting, image quality, imaging conditions (and cameras), etc. The various metadata we offer for each face provides opportunities for comparison and evaluation of a large number of common vision tasks, such as face detection, fiducial point detection, and pose angle detection. In addition, the large number of manually labeled attributes we provide -- <b>5,000</b> -- can be used for training and evaluating different learning tasks and algorithms. Finally, we also provide the URL of the page each face image was found on. This can be used to analyze the webpage and its links to obtain more information about the images. We are excited to see how the research community tackles these and other problems using our dataset.</p>
<p>Due to privacy and copyright issues, we are unable to provide the actual images of each face. Instead, we provide the URLs of each image, as well as the URL of the page the image was found on. Simple programs and libraries can be used to download the images (e.g., the command line tool <a href="http://www.gnu.org/software/wget/">wget</a>. Note that the dataset may slowly shrink over time as links disappear. In rare cases, a different image might be put up at the same location as an image from our dataset. In this case, the provided metadata and attribute labels will become incorrect.</p>
<p><b>This database is made available only for non-commercial use.</b></p>
<p>The database is organized into a variety of text files, which are all easy to parse. The first two lines of each file are comments (start with '#') -- the first is an identification of the file, while the second describes the format of each subsequent line in the file. Each line generally contains a face id followed by various other information, all separated by tabs ('\t'). The face id uniquely defines a face, and these ids are the common element linking all the files.</p>
<p>In detail, these are the different files:</p><p>
</p><ul>
	<li><a href="https://cave.cs.columbia.edu/old/databases/facetracer/files/faceindex.txt">faceindex.txt</a>: This contains a list of all faces with image urls and page urls. Each line contains the face id, followed by the image url (where the image is actually located), and then the page url (the page on which the image was located), all separated by tabs. Note that since many faces can come from a single image, the image and page urls for multiple face ids can be identical. The face rectangle (defined in the next file) will differentiate these faces.<br><br></li>
	<li><a href="https://cave.cs.columbia.edu/old/databases/facetracer/files/facestats.txt">facestats.txt</a>: This contains statistics for each face. Each line contains all of the statistics (as integers) for one face, separated by tabs. In order, these are:
		<ul>
			<li>The face id</li>
			<li>The width of the face in pixels</li>
			<li>The height of the face in pixels</li>
			<li>The x-location of the top-left corner of the face</li>
			<li>The y-location of the top-left corner of the face</li>
			<li>The pose angles (in degrees) of the face:<ul>
				<li>The yaw angle (out-of-plane left-right) of the face</li>
				<li>The pitch angle (up-down) of the face</li>
				<li>The roll angle (in-plane left-right) of the face</li>
				</ul></li>
			<li>The fiducial points of the face (all relative to the top-left of the face rectangle):<ul>
				<li>The x-location of the left corner of the left eye</li>
				<li>The y-location of the left corner of the left eye</li>
				<li>The x-location of the right corner of the left eye</li>
				<li>The y-location of the right corner of the left eye</li>
				<li>The x-location of the left corner of the right eye</li>
				<li>The y-location of the left corner of the right eye</li>
				<li>The x-location of the right corner of the right eye</li>
				<li>The y-location of the right corner of the right eye</li>
				<li>The x-location of the left corner of the mouth</li>
				<li>The y-location of the left corner of the mouth</li>
				<li>The x-location of the right corner of the mouth</li>
				<li>The y-location of the right corner of the mouth</li>
				</ul></li>
		</ul><br><br></li>
	<li><a href="https://cave.cs.columbia.edu/old/databases/facetracer/files/attributes.txt">attributes.txt</a>: This contains a list of the different attributes we have labeled, along with the valid labels for each attribute. There are 10 attributes, with a total of 25 different labels. Each line contains first the attribute name (lower case, no spaces), followed by all valid labels for that attribute (each lower case, no spaces). All elements are separated by tabs.<br><br></li>
	<li><a href="https://cave.cs.columbia.edu/old/databases/facetracer/files/facelabels.txt">facelabels.txt</a>: This contains a list of various attribute labels assigned to faces (5,000 in all). These labels were assigned manually by a group of people, and then manually verified by a single person to ensure consistency. Each line contains a single face id, attribute name, and label, separated by tabs. The attribute name and label are the exact same strings found in the "attributes.txt" file. Since many faces have multiple labeled attributes, face ids may be repeated (i.e., if a given face has two attributes labeled, then there will be two lines starting with that face id).<br><br></li>
	<li><a href="https://cave.cs.columbia.edu/old/databases/facetracer/files/facetracer.py">facetracer.py</a>: A simple python script that demonstrates how to parse the dataset and display information about a particular face in the dataset.<br><br></li>
	<li><a href="https://cave.cs.columbia.edu/old/databases/facetracer/files/facetracer.zip">facetracer.zip</a>: This contains all of the above files in a zip file, for ease in downloading.<br><br></li>
</ul>
<p>Please contact Neeraj Kumar (neeraj [at] cs.columbia.edu) for any questions or comments regarding this database.</p>
<p><b>If you use this database, please cite the "FaceTracer" paper listed below! The database is made available only for non-commercial use.</b>
</p></td></tr>
				<tr>
					<td><h3 id="Publications">Publications</h3>
						<blockquote>
				<b>"FaceTracer: A Search Engine for Large Collections of Images with Faces," </b><br>N. Kumar, P. N. Belhumeur, and S. K. Nayar, <br>European Conference on Computer Vision (ECCV), <br>pp. 340-353, Oct. 2008.<br> [<a href="http://www1.cs.columbia.edu/CAVE/publications/./pdfs/Kumar_ECCV08.pdf	" target="new">PDF</a>] [<a href="https://cave.cs.columbia.edu/reference?bid=235">bib</a>] [<a href="https://cave.cs.columbia.edu/copyright">©</a>] [<a href="https://cave.cs.columbia.edu/projects/categories/project?cid=Visual+Recognition&pid=FaceTracer+A+Search+Engine+for+Large+Face+Collections" target="new">Project Page</a>]<br><br><b>"Face Swapping: Automatically Replacing Faces in Photographs," </b><br>D. Bitouk, N. Kumar, S. Dhillon, P.N. Belhumeur and S. K. Nayar, <br>ACM Trans. on Graphics (also Proc. of ACM SIGGRAPH), <br>Aug. 2008.<br> [<a href="http://www1.cs.columbia.edu/CAVE/publications/./pdfs/Bitouk_SIGGRAPH08.pdf		" target="new">PDF</a>] [<a href="https://cave.cs.columbia.edu/reference?bid=239">bib</a>] [<a href="https://cave.cs.columbia.edu/copyright">©</a>] [<a href="/projects/categories/project?cid=Graphics+%26+Displays&pid=Face+Swapping+Automatically+Replacing+Faces+in+Photographs" target="new">Project Page</a>]<br><br>
						</blockquote>
					</td>
				</tr>
				
				<tr>
					<td align="left">
						<h3 id="Images">Images</h3>
				
						<table border="0" cellpadding="0" cellspacing="6">
				
						<tbody><tr>
							<td align="left" valign="middle" width="171"><a href="https://cave.cs.columbia.edu/old/databases/facetracer/images/facerect.png"><img class="imgthumb" src="https://cave.cs.columbia.edu/old/databases/facetracer/images/facerect_thumb.png" border="1"></a></td>
							<td align="left" valign="middle" width="10">&nbsp;</td>
							<td align="left" valign="middle" width="512">
								<strong>The face rectangle:</strong>
								<p class="mediadescription">This image shows a sample face rectangle automatically detected using our face
detector. Note that as given, the rectangle is not a very tight crop. In
particular, the original, tight crop rectangle was extended by 50% in each
direction (clipping at image boundaries). The given crop is useful because it
usually contains the entire face as well as most of the hair and some of the
context.</p>
							</td>
						</tr>
						<tr>
							<td height="8">&nbsp;</td>
							<td height="8">&nbsp;</td>
							<td height="8">&nbsp;</td>
						</tr>
					
						<tr>
							<td align="left" valign="middle" width="171"><a href="https://cave.cs.columbia.edu/old/databases/facetracer/images/facepose.png"><img class="imgthumb" src="https://cave.cs.columbia.edu/old/databases/facetracer/images/facepose_thumb.png" border="1"></a></td>
							<td align="left" valign="middle" width="10">&nbsp;</td>
							<td align="left" valign="middle" width="512">
								<strong>The pose angles:</strong>
								<p class="mediadescription">This image shows the 3 pose angles automatically detected using our face
detector. Each of the yaw, pitch, and roll angles are given in degrees.</p>
							</td>
						</tr>
						<tr>
							<td height="8">&nbsp;</td>
							<td height="8">&nbsp;</td>
							<td height="8">&nbsp;</td>
						</tr>
					
						<tr>
							<td align="left" valign="middle" width="171"><a href="https://cave.cs.columbia.edu/old/databases/facetracer/images/facefids.png"><img class="imgthumb" src="https://cave.cs.columbia.edu/old/databases/facetracer/images/facefids_thumb.png" border="1"></a></td>
							<td align="left" valign="middle" width="10">&nbsp;</td>
							<td align="left" valign="middle" width="512">
								<strong>The fiducial points:</strong>
								<p class="mediadescription">This image shows the 6 fiducial points automatically detected using our face
detector. These are the left and right corners of each eye and of the mouth.</p>
							</td>
						</tr>
						<tr>
							<td height="8">&nbsp;</td>
							<td height="8">&nbsp;</td>
							<td height="8">&nbsp;</td>
						</tr>
					
						</tbody></table>
					</td>
				</tr>
				
				<tr>
					<td align="left">
						<h3 id="Related Projects">Related Projects</h3>
						<blockquote>
				<p><a href="/projects/categories/project?cid=Graphics+%26+Displays&pid=Face+Swapping+Automatically+Replacing+Faces+in+Photographs">FaceTracer: A Search Engine for Large Collections of Images with Faces</a></p>
<p><a href="/projects/categories/project?cid=Graphics+%26+Displays&pid=Face+Swapping+Automatically+Replacing+Faces+in+Photographs">Face Swapping: Automatically Replacing Faces in Photographs</a></p>
<p><a href="http://vis-www.cs.umass.edu/lfw/">Labeled Faces in the Wild (UMass Project and Database)</a></p>

						</blockquote>
					</td>
				</tr>
				
			<tr><td height="20"></td></tr>
			</tbody></table>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
	font-size: 18px;
}
.table{
        margin:auto;
}
</style>